<app-btn-nuevo-usuario></app-btn-nuevo-usuario>
<br />
<br />
<mat-card *appRole="['Usuarios.Ver']">
  <mat-card-header>
    <ngx-spinner
      name="spListadoUsuarios"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" #009688"
      type="ball-atom"
      [fullScreen]="false"
    >
    </ngx-spinner>
    <div class="row align-items-center">
      <div class="col-auto">
        <button mat-stroked-button color="primary" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>
      </div>
      <div class="col-auto">
        <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
          Filtros Avanzados
        </mat-slide-toggle>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="row align-items-center">
      <!-- <div class="col">
          <button mat-raised-button [matMenuTriggerFor]="menu" color="primary"  *ngIf="data">Acciones
           <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
          </mat-menu>
        </div> -->
      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </div>
    </div>

    <mat-slide-toggle
      [(ngModel)]="isChecked"
      style="margin-left: 10px"
      color="accent"
      (click)="checked(isChecked)"
      *ngIf="data"
    >
      Activos
    </mat-slide-toggle>

    <table
      class="mat-elevation-z8"
      mat-table
      [dataSource]="dataSource"
      *ngIf="data"
      matSort
      matSortStart="asc"
    >
      <ng-container matColumnDef="userName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Nombre de usuario</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario">
          {{ usuario.userName | lowercase }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="nombreCompleto">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Nombre Completo</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario"
          ><span>
            {{ usuario.nombre | titlecase }} {{ usuario.apellido | titlecase }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="telefono">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Telefono</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario">
          {{ usuario.telefono }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="correo">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Correo</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario">
          <a href="mailto:{{ usuario.correo }}">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-envelope-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
              />
            </svg>
            {{ usuario == undefined ? "" : usuario.correo }}
          </a>
        </mat-cell>
      </ng-container>
      <!-- Permiso  Column -->
      <ng-container matColumnDef="rol">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Rol</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario">
          {{ usuario.rol == undefined ? "" : usuario.rol.descripcion }}
        </mat-cell>
      </ng-container>
      <!-- Estado  Column -->
      <ng-container matColumnDef="estado">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Estado</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario">
          <app-status-badge  [estado]="usuario.activo" ></app-status-badge>
        </mat-cell>
      </ng-container>

      <!-- Accion Column -->
      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Acción</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let usuario" class="action-link">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button> -->
            <button mat-menu-item [routerLink]="['/usuarios', usuario.id]">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item (click)="openDialog('Eliminar', usuario)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        matRipple
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </table>
    <app-sin-datos-para-mostrar
      [dataSource]="dataSource"
    ></app-sin-datos-para-mostrar>
    <mat-paginator
      showFirstLastButtons
      class="paginator"
      *ngIf="data"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
