import { Component, OnInit } from '@angular/core';
//Servicios
import { PersonaService } from '@services/persona/persona.service';
import { EstadoCivilService } from "@services/estadoCivil/estadoCivil.service";
import { TipoDocumentoService } from "@services/tipoDocumento/tipoDocumento.service";
//Models
import { EstadoCivilDto } from "@models/estadoCivil/estadoCivilDto.model";
import { TipoDocumentoDto } from "@models/tipoDocumento/tipoDocumentoDto.model";
import { CreatePersonaRequest } from "@models/persona/createPersonaRequest.model"
//
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
//VAlidaciones
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { PaisService } from '@services/pais/pais.service';
import { TiposDocumentos } from '@models/base/identificadores.model';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { UsuarioService } from '@services/usuario/usuario.service';
import { LoginService } from '@services/login/login.service';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';

@Component({
  selector: 'app-form-persona-create',
  templateUrl: './form-persona-create.component.html',
  styleUrls: ['./form-persona-create.component.css']
})
export class FormPersonaCreateComponent implements OnInit {

  panelOpenState = true;
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  personaForm!: FormGroup
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  lstEstadosCivilModel: EstadoCivilDto[] = [];
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  lstPaises: BaseDto[] = [];
  localidad!: BaseDto
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  idLocalidad: number = 0;
  idEstadoCivil: number = 0;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  accordionList: any;
  formBuilder: FormBuilder = new FormBuilder;
  usuarioDto!: UsuarioDto;

  get f() { return this.personaForm.controls; }

  localidadSelec: number = 0;
  formLocalidad: FormControl = new FormControl("", Validators.compose([Validators.required]));
  filteredOptions?: Observable<any[]>;


  constructor(
    private personaService: PersonaService,
    private loginService: LoginService,
    private tipoDocumentoService: TipoDocumentoService,
    private paisService: PaisService,
    private router: Router,
    private snackBar: SnackBarService,
    private estadoCivilService: EstadoCivilService,
    private spinner: NgxSpinnerService,

  ) {

    this.personaForm = this.formBuilder.group({
      //Persona
      Nombre: ["", Validators.compose([Validators.required, Validators.maxLength(150)])],
      Apellido: ['', Validators.compose([Validators.maxLength(150)])],
      NroDocumento: ['',
        Validators.compose([ Validators.maxLength(50)])],
        // this.validaPersonaByNroDocYTipoDoc.bind(this)],
      TelefonoPrincipal: ['', Validators.compose([Validators.required,Validators.maxLength(30)])],
      TelefonoAlternativo: ['',Validators.compose([Validators.maxLength(30)])],
      TelefonoLaboral: ['',Validators.compose([Validators.maxLength(30)])],
      Sexo: [''],
      Correo: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      FechaNacimiento: [''],
      Ubicacion: ['', Validators.compose([Validators.maxLength(200)])],
      UbicacionLaboral: ['', Validators.compose([Validators.maxLength(150)])],
      Pais: [''],
      CodigoPostal: ['', Validators.compose([Validators.maxLength(8)])],
      EstadoCivil: [''],
      TiposDocumento: [''],
      Ocupacion: ['', Validators.compose([Validators.maxLength(50)])],
      Observacion: ['', Validators.compose([Validators.maxLength(2000)])]
    },
      {
        //validator:tipoDocNroDocValidator(this.idPersona,"TiposDocumento","NroDocumento",PersonaService)
      });


  }

  ngOnInit(): void {
    this.usuarioDto = this.loginService.getCurrentUser()
    this.idPersona = 0
    this.getAllPaises();
    this.getAllTiposDocumentos();
    this.getAllEstadosCivil();
    this.setDefault()
  }

  validaDocumentosSegunTipoDocumento(){
    this.personaForm.controls["TiposDocumento"].valueChanges.subscribe(valor => {
      //Obtenemos el control ya instanciado en el formulario.
      let tiposDocumentoControl = this.personaForm.controls["TiposDocumento"];

      //Quitamos todas las validaciones del control.
      //tiposDocumentoControl.clearValidators();

      //Agregamos la validacion segun el caso:
      switch (valor) {
        case TiposDocumentos.DNI:
          //Se agregan de nuevo todas las validaciones que necesites.
          //En este caso solo agregue la que me parecio oportuna.
          tiposDocumentoControl.setValidators([Validators.pattern("^[0-9]*$")]);
          break;
        case TiposDocumentos.PASS:
          //En el caso del alfanumerico no es necesario aplicar
          // validaciones. Si necesitas un required aqui lo debes poner.
          break;
      }

      //Para evitar problemas con la validacion marcamos el campo con
      // dirty, de esta manera se ejecutan de nuevo las validaciones
      tiposDocumentoControl.markAsDirty()
      //Recalculamos el estado del campo para que cambie el estado
      // del formulario.
      tiposDocumentoControl.updateValueAndValidity()
    });
  }

  setDefault() {
    this.personaForm.controls["NroDocumento"].disable();
    this.personaForm.controls["Pais"].setValue(11);
  }

  selectTipoDocumento(event: any) {
    this.idTipoDocumento = event
    if (this.idTipoDocumento != 0) {
      this.personaForm.controls["NroDocumento"].enable();
      this.personaForm.controls["NroDocumento"].setValue("");
    }
    else
      this.personaForm.controls["NroDocumento"].disable();

  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }

  onSubmit() {
    this.submitted = true;
    if (this.personaForm.invalid) {
      return;
    }
    const createPersonaRequest: CreatePersonaRequest = this.setPersonaDetailDto();
    this.spinner.show("spPersona")
    this.personaService.create(createPersonaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.idPersona = data.entidad.id
          this.snackBar.showSuccess(this.personaForm.value['Nombre'] + ", " +this.personaForm.value['Apellido'] +
            ' se registro correctamente.', "Exito");
          this.router.navigate(["/personas/" + data.entidad.id])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

   getAllTiposDocumentos() {
    this.spinner.show("spTiposDocumento")
    this.tipoDocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTiposDocumento")
          this.lstTipoDocumentoDto = data
        },
        error => {
          this.spinner.hide("spTiposDocumento")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllPaises() {
    this.spinner.show("spPaises")
    this.paisService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spPaises")
          this.lstPaises = data
        },
        error => {
          this.spinner.hide("spPaises")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosCivil() {
    this.spinner.show("spEstadosCivil")
    this.estadoCivilService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosCivil")
          this.lstEstadosCivilModel = data
        },
        error => {
          this.spinner.hide("spEstadosCivil")
          this.snackBar.showError(error, "Error");
        })
  }

  setPersonaDetailDto(): CreatePersonaRequest {

    const createPersonaRequest = new CreatePersonaRequest();


    var fecha = this.personaForm.controls['FechaNacimiento'].value.split('-');
    var fechaCompleta = "";
    if (fecha != "") {
      var anio = fecha[0];
      var mes = fecha[1];
      var dia = fecha[2];
      fechaCompleta = anio + "-" + mes + "-" + dia;
    }

    createPersonaRequest.nombre = this.personaForm.controls['Nombre'].value;
    createPersonaRequest.apellido = this.personaForm.controls['Apellido'].value;
    createPersonaRequest.nroDocumento = this.personaForm.controls['NroDocumento'].value;
    createPersonaRequest.idTipoDocumento = isFalsy(this.personaForm.controls['TiposDocumento'].value) ? null : this.personaForm.controls['TiposDocumento'].value;
    createPersonaRequest.idEstadoCivil = isFalsy(this.personaForm.controls['EstadoCivil'].value) ? null : this.personaForm.controls['EstadoCivil'].value;
    createPersonaRequest.telefonoPrincipal = this.personaForm.controls['TelefonoPrincipal'].value
    createPersonaRequest.telefonoAlternativo = //this.personaForm.controls['TelefonoAlternativo'].value
    createPersonaRequest.telefonoLaboral = //this.personaForm.controls['TelefonoLaboral'].value
    createPersonaRequest.sexo = isFalsy(this.personaForm.controls['Sexo'].value)? null: this.personaForm.controls['Sexo'].value;
    createPersonaRequest.correo = this.personaForm.controls['Correo'].value;
    createPersonaRequest.fechaNacimiento = fechaCompleta;
    createPersonaRequest.ubicacion = this.personaForm.controls['Ubicacion'].value;
    createPersonaRequest.ubicacionLaboral = this.personaForm.controls['UbicacionLaboral'].value;
    createPersonaRequest.idPais = isFalsy(this.personaForm.controls['Pais'].value) ? null : this.personaForm.controls['Pais'].value;
    createPersonaRequest.idLocalidad = isFalsy(this.localidad) ? null : this.localidad.id
    createPersonaRequest.codigoCpa = this.personaForm.controls['CodigoPostal'].value
    createPersonaRequest.observacion = this.personaForm.controls['Observacion'].value
    createPersonaRequest.ocupacion = this.personaForm.controls['Ocupacion'].value
    createPersonaRequest.activo = true;
    createPersonaRequest.idEmpresa = this.usuarioDto.empresa.id;
    createPersonaRequest.idUsuario = this.usuarioDto.id;

    return createPersonaRequest
  }

  onReset() {
    this.submitted = false;
    this.personaForm.reset();
  }

  goToPesonas() {
    this.submitted = false;
    this.personaForm.reset();
    this.router.navigate(["/personas"])
  }

  // validaPersonaByNroDocYTipoDoc(controlNroDoc: FormControl) {
  //   let idTipoDocumento = this.personaForm.controls['TiposDocumento'].value;
  //   if (controlNroDoc.value.toString().length > 7  && !isFalsy(idTipoDocumento) ) {
  //     let promesa = new Promise((resolve, reject) => {
  //       this.spinner.show("spPersonaCombo")
  //       this.PersonaService.validaPersonaByNroDocYTipoDoc(this.idPersona, controlNroDoc.value, idTipoDocumento)
  //         .subscribe(data => {
  //           if (data == true) {
  //             this.spinner.hide("spPersonaCombo")
  //             resolve({ existePersona: true })
  //           }
  //           else {
  //             this.spinner.hide("spPersonaCombo")
  //             resolve(true)
  //           }
  //         })
  //     });
  //     //
  //     return promesa;
  //   }
  //   else {
  //     let promesa = new Promise((resolve, reject) => {
  //       resolve(true)
  //     });
  //     return promesa;
  //   }
  // }

  isFalsy(valor:any){
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
