<div *appRole="['Movimientos.Crear']">
  <button mat-raised-button color="primary" (click)="openDialog('Agregar', {})">
    <mat-icon>add</mat-icon>
    Nuevo movimiento
  </button>
</div>
<br />
<mat-card *appRole="['Movimientos.Listar']">
  <mat-card-header *ngIf="idExpediente == undefined">
    <div class="row align-items-center">
      <div class="col-auto">
        <button mat-stroked-button color="primary" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>
      </div>
      <div class="col-auto">
        <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
          Filtros Avanzados
        </mat-slide-toggle>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <ngx-spinner
      [zIndex]="100"
      name="spListado"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" var(--primary-color)"
      type="ball-atom"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div class="row align-items-center">
      <div class="col">
        <!-- <button mat-raised-button [matMenuTriggerFor]="menu" color="primary"  *ngIf="data">Acciones
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
        </mat-menu> -->
      </div>
      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </div>
    </div>

    <table
      class="mat-elevation-z8"
      mat-table
      [dataSource]="dataSource"
      *ngIf="data"
      matSort
      matSortStart="asc"
    >
      <ng-container matColumnDef="expediente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Caratula</strong>
        </th>
        <td mat-cell *matCellDef="let movimiento">
          <a [routerLink]="'/expedientes/' + movimiento.expediente?.id"
            ><span>{{
              movimiento.expediente == undefined
                ? ""
                : (movimiento.expediente?.caratula | uppercase)
            }}</span></a
          >
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <span><strong>Total</strong></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="tipoMovimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Tipo movimiento</strong>
        </th>
        <td mat-cell *matCellDef="let movimiento">
          <span>{{
            movimiento.tipoMovimiento == undefined
              ? ""
              : (movimiento.tipoMovimiento?.descripcion | titlecase)
          }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="importe">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Importe</strong>
        </th>
        <td mat-cell *matCellDef="let movimiento">
          <span>{{ movimiento.importe | currency }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <span
            ><strong>{{ getTotal() | currency }}</strong></span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="fechaMovimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Fecha</strong>
        </th>
        <td mat-cell *matCellDef="let movimiento">
          <span>{{ movimiento.fechaMovimiento | date : "dd/MM/yyyy" }}</span>
        </td>

        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <!-- Accion Column -->
      <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef><strong>Acción</strong></th>
        <td mat-cell *matCellDef="let item" class="action-link">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openDialog('Ver', item)">
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button>
            <button mat-menu-item (click)="openDialog('Modificar', item)">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item (click)="openDialog('Eliminar', item)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
    <app-sin-datos-para-mostrar
      [dataSource]="dataSource"
    ></app-sin-datos-para-mostrar>
    <mat-paginator
      showFirstLastButtons
      class="paginator"
      *ngIf="data"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
