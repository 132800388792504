<h2>Nueva nota</h2>
<mat-dialog-content>
  <ngx-spinner
    [zIndex]="100"
    name="spAudiencia"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
      <ngx-editor
        [editor]="editor"
        formControlName="Descripcion"
        [placeholder]="'Escriba aqui...'"
      ></ngx-editor>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    style="margin-right: 3px"
    name="Agregar"
    [disabled]="!form.valid || data.idExpediente == undefined"
    (click)="onSubmit()"
  >
  <span>Agregar</span>
  </button>
</mat-dialog-actions>
