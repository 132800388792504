<mat-card *appRole="['Personas.Listar']">
  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <!-- Tipo Documento -->
        <div class="col-12 col-md-6 col-lg-2 mb-2">
          <mat-form-field class="w-100" appearance="outline">
            <ngx-spinner
              [zIndex]="-1"
              name="spBusquedaTipoDoc"
              bdColor="rgba(255,255,255,0.8)"
              size="small"
              color=" var(--primary-color)"
              type="line-scale"
              [fullScreen]="false"
            ></ngx-spinner>
            <mat-label matTooltip="Tipo de documento">Tipo Documento</mat-label>
            <mat-select
              placeholder="Seleccione opción"
              formControlName="TipoDocumento"
            >
              <mat-option
                [value]="tipoDocumento.id"
                *ngFor="let tipoDocumento of lstTiposDocumento"
              >
                {{ tipoDocumento.descripcion | uppercase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Número Documento -->
        <div class="col-12 col-md-6 col-lg-2 mb-3">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label matTooltip="Número de documento"
              >Nro. Documento</mat-label
            >
            <input
              matInput
              type="text"
              maxlength="150"
              formControlName="NroDocumento"
            />
          </mat-form-field>
        </div>

        <!-- Nombre -->
        <div class="col-12 col-md-6 col-lg-2 mb-2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input
              matInput
              type="text"
              maxlength="150"
              formControlName="Nombre"
            />
          </mat-form-field>
        </div>

        <!-- Apellido -->
        <div class="col-12 col-md-6 col-lg-2 mb-2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Apellido</mat-label>
            <input
              matInput
              type="text"
              maxlength="150"
              formControlName="Apellido"
            />
          </mat-form-field>
        </div>

        <!-- Correo -->
        <div class="col-12 col-md-6 col-lg-2 mb-2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Correo</mat-label>
            <input
              matInput
              type="text"
              maxlength="150"
              formControlName="Correo"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <mat-checkbox formControlName="Activo" aria-label="Activo">
            Activo
          </mat-checkbox>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto">
          <button mat-stroked-button color="primary" (click)="filtrar()">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
          </button>
        </div>
        <div class="col-auto">
          <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
            Filtros Avanzados
          </mat-slide-toggle>
        </div>
      </div>
    </form>

    <ngx-spinner
      [zIndex]="100"
      name="spListado"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" var(--primary-color)"
      type="ball-atom"
      [fullScreen]="false"
    ></ngx-spinner>

    <div *ngIf="data">
      <div class="row align-items-center">
        <div class="col">
          <!-- <button mat-raised-button [matMenuTriggerFor]="menu" color="primary"  *ngIf="data">Acciones
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
          </mat-menu> -->
        </div>
        <div class="col-lg-4 col-sm-12 text-end">
          <mat-form-field appearance="outline" *ngIf="data" class="w-100">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Buscar</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Buscar"
              #input
            />
          </mat-form-field>
        </div>
      </div>

      <!-- Tabla de Datos -->
      <table
        class="mat-elevation-z8"
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortStart="asc"
      >
        <ng-container matColumnDef="nombreCompleto">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Nombre Completo</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let persona">{{
            persona.nombreCompleto | titlecase
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="documento">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Documento</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let persona"
            >{{ persona.tipoDocumento }} - {{ persona.nroDocumento }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="correo">
          <mat-header-cell *matHeaderCellDef
            ><strong>Correo</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let persona">{{ persona.correo }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="estado">
          <mat-header-cell *matHeaderCellDef
            ><strong>Estado</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let persona">
            <app-status-badge [estado]=" persona.activo "></app-status-badge>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="accion">
          <mat-header-cell *matHeaderCellDef
            ><strong>Acción</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let persona">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Opciones"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/personas/', persona.id]">
                <mat-icon>visibility</mat-icon> Ver
              </button>
              <button mat-menu-item (click)="editarPersona(persona)">
                <mat-icon>edit</mat-icon> Editar
              </button>
              <button mat-menu-item (click)="openDialog(persona)">
                <mat-icon>delete</mat-icon> Eliminar
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
    </div>

    <app-sin-datos-para-mostrar
      [dataSource]="dataSource"
    ></app-sin-datos-para-mostrar>

    <!-- Paginación -->
    <mat-paginator
      showFirstLastButtons
      class="paginator"
      *ngIf="data"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
