import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogUpdateContactoComponent } from '@views/contactos/dialog-update-contacto/dialog-update-contacto.component';
import { DialogCreateContactoComponent } from '@views/contactos/dialog-create-contacto/dialog-create-contacto.component';
import { DialogViewContactoComponent } from '@views/contactos/dialog-view-contacto/dialog-view-contacto.component';
import { ListContactoComponent } from '@views/contactos/list-contacto/list-contacto.component';
import { SharedModule } from './shared.module';
import { CardViewContactosSimpleComponent } from '@views/contactos/card-view-contactos-simple/card-view-contactos-simple.component';
import { StatusBadgeComponent } from "../component/status-badge/status-badge.component";
import { ComponentModule } from './component.module';

@NgModule({
    imports: [
    CommonModule,
    SharedModule,
    ComponentModule,
    StatusBadgeComponent
],
    declarations: [
      ListContactoComponent,
      DialogViewContactoComponent,
      DialogCreateContactoComponent,
      DialogUpdateContactoComponent,
      CardViewContactosSimpleComponent
    ],

    providers: [
    ],
    exports: [
      ListContactoComponent,
      DialogViewContactoComponent,
      DialogCreateContactoComponent,
      DialogUpdateContactoComponent,
      CardViewContactosSimpleComponent
    ],
    schemas: []
})
export class ContactoModule { }
