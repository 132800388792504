<div *appRole="['Organismos.Crear']">
  <button
    mat-raised-button
    color="primary"
    (click)="openDialog('Agregar', {})"
    #tooltip="matTooltip"
    matTooltip="Crear pericia"
  >
    <mat-icon>add</mat-icon>
    <span>Nuevo</span>
  </button>
</div>
<br />
<mat-card *appRole="['Organismos.Listar']">
  <mat-card-header>
    <form [formGroup]="formFilter">
      <div class="row">
        <div class="col-lg-12">
          <app-buscar-localidad
            (eventoLocalidad)="seleccionaLocalidad($event)"
          ></app-buscar-localidad>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto">
          <button mat-stroked-button color="primary" (click)="filtrar()">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
          </button>
        </div>
        <div class="col-auto">
          <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
            Filtros Avanzados
          </mat-slide-toggle>
        </div>
      </div>
    </form>
    <br />
    <br />
  </mat-card-header>
  <mat-card-content>
    <ngx-spinner
      [zIndex]="100"
      name="spListado"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" var(--primary-color)"
      type="ball-atom"
      [fullScreen]="false"
    >
    </ngx-spinner>
    <br />

    <div class="row align-items-center">
      <!-- <div class="col">
        <button mat-raised-button [matMenuTriggerFor]="menu" color="primary"  *ngIf="data">Acciones
         <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
        </mat-menu>
      </div> -->
      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </div>
    </div>

    <table
      class="mat-elevation-z8"
      mat-table
      [dataSource]="dataSource"
      *ngIf="data"
      matSort
      matSortStart="asc"
    >
      <ng-container matColumnDef="nombre">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Nombre</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let organismo">
          {{
            organismo.nombre == undefined ? "" : (organismo.nombre | uppercase)
          }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="telefono">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Telefono</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let organismo">
          {{
            organismo.telefono == undefined
              ? ""
              : (organismo.telefono | uppercase)
          }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="correo">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Correo</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let organismo">
          {{
            organismo.correo == undefined ? "" : (organismo.correo | uppercase)
          }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="localidad">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Localidad</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let organismo">
          {{
            organismo.localidad == undefined
              ? ""
              : (organismo.localidad.nombreCompleto | uppercase)
          }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Acción</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let organismo" class="action-link">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              [routerLink]="['/organismos/', organismo.id]"
              *appRole="['Organismos.Modificar']"
            >
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button>
            <button mat-menu-item (click)="openDialog('Modificar', organismo)">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item (click)="openDialog('Eliminar', organismo)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        matRipple
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </table>
    <app-sin-datos-para-mostrar
      [dataSource]="dataSource"
    ></app-sin-datos-para-mostrar>
    <mat-paginator
      showFirstLastButtons
      class="paginator"
      *ngIf="data"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
