<button
  mat-raised-button
  *appRole="['TiposFolio.Crear']"
  disabled
  (click)="openDialog('Agregar', {})"
  color="primary"
>
  <mat-icon>add</mat-icon>
  <span>Nuevo</span>
</button>
<br /><br />
<mat-card *appRole="['TiposFolio.Ver']">
  <ngx-spinner
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>

  <div class="row align-items-center">
    <!-- <div class="col">
        <button mat-raised-button [matMenuTriggerFor]="menu" color="primary"  *ngIf="data">Acciones
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
        </mat-menu>
      </div> -->
    <div class="col-lg-4 col-sm-12 text-end">
      <mat-form-field appearance="outline" *ngIf="data" class="w-100">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Buscar</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. ium"
          #input
        />
      </mat-form-field>
    </div>
  </div>

  <table
    class="mat-elevation-z8"
    mat-table
    [dataSource]="dataSource"
    *ngIf="data"
    table
    matSort
    matSortStart="asc"
    class="mat-elevation-z8"
  >
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Id Column -->
    <!-- <ng-container matColumnDef="id">
          <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container> -->

    <!-- Name Column -->
    <ng-container matColumnDef="descripcion">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
        Descripción
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        {{ element.descripcion }}
      </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="accion">
      <mat-header-cell mat-header-cell *matHeaderCellDef>
        Acción
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" class="action-link">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button> -->
          <button
            mat-menu-item
            disabled
            *appRole="['TiposFolio.Modificar']"
            (click)="openDialog('Modificar', element)"
          >
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button
            mat-menu-item
            *appRole="['TiposFolio.Eliminar']"
            disabled
            (click)="openDialog('Eliminar', element)"
          >
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      matRipple
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </table>
  <app-sin-datos-para-mostrar
    [dataSource]="dataSource"
  ></app-sin-datos-para-mostrar>
  <mat-paginator
    showFirstLastButtons
    class="paginator"
    *ngIf="data"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
  >
  </mat-paginator>
</mat-card>
