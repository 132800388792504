<ngx-spinner
  bdColor="rgba(255,255,255,0.8)"
  size="default"
  color=" var(--primary-color)"
  type="ball-atom"
  [fullScreen]="true"
>
</ngx-spinner>
<mat-toolbar class="toolbar">
  <mat-menu #buscar="matMenu" xPosition="before">
    <app-menu-setting></app-menu-setting>
  </mat-menu>

  <app-api-version></app-api-version>
  <!-- <app-btn-notificaciones></app-btn-notificaciones> -->

  <button
    mat-icon-button
    [matMenuTriggerFor]="beforeMenu"
    [matTooltip]="
      'Nombre y Apellido: ' +
      user.nombre +
      ' ' +
      user.apellido +
      '\n Usuario: ' +
      user.userName
    "
    [matTooltipClass]="'allow-cr'"
  >
    <mat-icon style="transform: scale(1.5)">account_circle</mat-icon>
  </button>

  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <app-menu-setting></app-menu-setting>
  </mat-menu>
</mat-toolbar>
