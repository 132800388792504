<app-go-back-and-title titulo="Ver Persona"></app-go-back-and-title>
<ngx-spinner [zIndex]="100" name="spPersona" bdColor="rgba(255,255,255,0.8)" size="default"
  color=" var(--primary-color)" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div *appRole="['Personas.Ver']">
  <div *ngIf="personaDetailDto">

    <mat-tab-group>
      <mat-tab label="Persona">
        <div class="container-fluid">
          <br>
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" class="app-button-action"
            matTooltip="Acciones">
            <mat-icon>more_vert</mat-icon> Acciones
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/personas/edit/' + personaDetailDto.id]">
              <mat-icon>edit</mat-icon>Editar
            </button>
            <button mat-menu-item (click)="openDialogDelete(personaDetailDto)">
              <mat-icon>delete</mat-icon>Eliminar
            </button>
          </mat-menu>
          <br><br>
          <div class="row">
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Información de Persona</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="property-row">
                    <span class="property-name">Nombre:</span>
                    <span class="property-value">{{ personaDetailDto.nombre | uppercase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Apellido:</span>
                    <span class="property-value">{{ personaDetailDto.apellido | uppercase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Tipo de Documento:</span>
                    <span class="property-value">{{
                      personaDetailDto.tipoDocumento?.descripcion | uppercase
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Nro. de Documento:</span>
                    <span class="property-value">{{ personaDetailDto.nroDocumento }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Estado Civil:</span>
                    <span class="property-value">{{
                      personaDetailDto.estadoCivil?.descripcion | titlecase
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Fecha Nacimiento:</span>
                    <span class="property-value">{{ personaDetailDto.fechaNacimiento | date : "short" }}</span>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Estado y Otras Informaciones</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="property-row">
                    <span class="property-name">Estado:</span>
                    <app-status-badge [estado]=" personaDetailDto.activo "></app-status-badge>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Fecha Alta:</span>
                    <span class="property-value">{{ personaDetailDto.fechaAlta | date }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Ultima Modificación:</span>
                    <span class="property-value">{{ personaDetailDto.fechaModificacion | date }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Observaciones:</span>
                    <span class="property-value"> {{ personaDetailDto.observacion }}</span>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6">
              <app-card-view-contactos-simple [contactos]="contactos"></app-card-view-contactos-simple>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Contactos">
        <div class="container-fluid">
          <br>
          <app-list-contacto [idPersona]="personaDetailDto.id"></app-list-contacto>
        </div>
      </mat-tab>
      <mat-tab label="Expedientes">
        <div class="container-fluid">
          <br>
          <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default"
          color=" var(--primary-color)" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceExpediente" *ngIf="dataExpediente"
          matSort matSortStart="asc">
          <ng-container matColumnDef="caratula">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
              <strong>Caratula</strong>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let expediente">
              <span>{{ expediente.caratula | uppercase }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cuij">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
              <strong>Cuij</strong>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let expediente">
              <span>{{ expediente.cuij | uppercase }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="estadoExpediente">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado Exte.</strong>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let expediente">
              <span>{{
                expediente.estadoExpediente == undefined
                ? ""
                : (expediente.estadoExpediente.descripcion
                | uppercase)
                }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="estadoExpedientePerito">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
              <strong>Estado Exte. Perito</strong>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let expediente">
              <span>{{
                expediente.estadoExpedientePerito == undefined
                ? ""
                : (expediente.estadoExpedientePerito.descripcion
                | uppercase)
                }}</span>
            </mat-cell>
          </ng-container>

          <!-- Accion Column -->
          <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef>
              <strong>Acción</strong>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let expediente" class="action-link">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['/expedientes/', expediente.id]"
                  *appRole="['Expedientes.Ver']">
                  <mat-icon>visibility</mat-icon>
                  <span>Ver</span>
                </button>
                <button mat-menu-item [routerLink]="['/expedientes/edit/', expediente.id]"
                  *appRole="['Expedientes.Modificar']">
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsExpediente"></mat-header-row>
          <mat-row matRipple *matRowDef="let row; columns: displayedColumnsExpediente"></mat-row>
        </table>
        <div class="no-data-table">
          <span class="with-icon"
            *ngIf="dataSourceExpediente.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
            datos
            para mostrar.</span>
        </div>
        <mat-paginator showFirstLastButtons class="paginator" *ngIf="dataExpediente" [pageSize]="20"
          [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
        </mat-paginator>
        </div>
      </mat-tab>
      <mat-tab label="Documentos" *appRole="['Documentos.Listar']">
        <div class="container-fluid">
          <br>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
