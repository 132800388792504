import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '@helpers/helper';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { ExpedienteFilter } from '@models/expediente/expedienteFilter.model';
import { DeletePersonaRequest } from '@models/persona/deletePersonaRequest.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { ContactoService } from '@services/contacto/contacto.service';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { PericiaService } from '@services/pericia/pericia.service';
import { PersonaService } from '@services/persona/persona.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from 'src/app/component/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactoDto } from '@models/contacto/contactoDto.model';

@Component({
  selector: 'app-form-persona-view',
  templateUrl: './form-persona-view.component.html',
  styleUrls: ['./form-persona-view.component.css']
})
export class FormPersonaViewComponent {
  personaDetailDto!:PersonaDetailDto;
  idPersona: any;
  dataSourceExpediente = new MatTableDataSource<ExpedienteDto>();
  dataExpediente: boolean = false
  displayedColumnsExpediente!: string[];
  contactos!: ContactoDto[];
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private personaService: PersonaService,
    private snackBar: SnackBarService,
    private periciasService: PericiaService,
    private breakpointObserver: BreakpointObserver,
    private contactoService: ContactoService,
    private expedienteService: ExpedienteService

  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsExpediente = result.matches ?
        ["caratula", "accion"] :
        ["caratula", "cuij", "estadoExpediente", "estadoExpedientePerito", "accion"];
    });

  }


  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  ngOnInit(): void {
    this.idPersona = this.activatedRoute.snapshot.params['id']
    this.getPersonaById(this.idPersona)
    this.getAllExpedienteByIdPersona(this.idPersona)
  }
  getPersonaById(id: number) {
    this.spinner.show("spPersona")
    this.personaService.getPersonaById(id)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personaDetailDto = data
          this.personaService.setPersona(data)
          this.getAllContactosByIdPersona(data.id)
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialogDelete(obj: any) {
    obj.id = obj.id
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.deleteRowData(result.data);
    });
  }

  deleteRowData(id: number) {
    this.spinner.show('spPersona')
    this.personaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spPersona')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.router.navigate(["/personas"])
        },
        error => {
          this.spinner.hide('spPersona')
          this.snackBar.showError(error, "Error");
        });
  }

  copiarAlPortapapeles(texto:string){
    Helper.copyToClipboard(texto)
  }


  getAllExpedienteByIdPersona(idPersona: number) {
    this.spinner.show('spExpediente')
    let expedienteFilter = new ExpedienteFilter
    expedienteFilter.idPersona = idPersona
    this.expedienteService.getAllExpedientesByFilter(expedienteFilter)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.dataSourceExpediente.data = data.sort((a, b) => a.caratula.toString().localeCompare(b.caratula.toString())) as ExpedienteDto[]
          this.dataExpediente = this.dataSourceExpediente.data.length >= 1 ? true : false

        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllContactosByIdPersona(idPersona: number) {
    this.spinner.show('spContactos')
    this.contactoService.getAllByIdPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide('spContactos')
          this.contactos = data

        },
        error => {
          this.spinner.hide('spContactos')
          this.snackBar.showError(error, "Error");
        })
  }
}
