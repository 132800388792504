<app-go-back-and-title titulo="Modificar Persona"></app-go-back-and-title>
<div *appRole="['Personas.Modificar']">

  <form [formGroup]="personaForm">
    <ngx-spinner [zIndex]="100" name="spPersona" bdColor="rgba(255,255,255,0.8)" size="default" color=" var(--primary-color)"
      type="ball-atom" [fullScreen]="false"></ngx-spinner>
    <mat-card style="margin-bottom: 20px">
      <mat-card-header>
        <mat-card-title>Datos de la Persona</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Nombre</mat-label>
              <input matInput type="text" maxlength="50" style="text-transform: capitalize" formControlName="Nombre" />
              <mat-error *ngIf="personaForm.controls['Nombre'].hasError('required')">Este campo es
                <strong>Requerido</strong></mat-error>
              <mat-error *ngIf="personaForm.controls['Nombre'].hasError('maxlength')">Largo máximo excedido</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Apellido</mat-label>
              <input matInput style="text-transform: capitalize" maxlength="50" formControlName="Apellido" />
              <mat-error *ngIf="personaForm.controls['Apellido'].hasError('maxlength')">Largo máximo
                excedido</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Tipo Documento</mat-label>
              <mat-select formControlName="TiposDocumento" (selectionChange)="selectTipoDocumento($event)">
                <mat-option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of lstTipoDocumentoDto">
                  {{ tipoDocumento.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Nro. Documento</mat-label>
              <input matInput maxlength="15" formControlName="NroDocumento"
                oninput="this.value=this.value.toUpperCase()" />
              <mat-error *ngIf="personaForm.controls['NroDocumento'].hasError('maxlength')">Largo máximo
                excedido</mat-error>
              <mat-error *ngIf="personaForm.controls['NroDocumento'].hasError('minlength')">Longitud mínima
                requerida</mat-error>
              <mat-error *ngIf="personaForm.controls['NroDocumento'].errors?.['pattern']">Caracteres
                inválidos</mat-error>
            </mat-form-field>

          </div>
        </div>

        <div class="row">

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Sexo</mat-label>
              <mat-select formControlName="Sexo">
                <mat-option value="M">Masculino</mat-option>
                <mat-option value="F">Femenino</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Fecha Nacimiento</mat-label>
              <input matInput type="date" formControlName="FechaNacimiento" />
            </mat-form-field>
          </div>


          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Estado Civil</mat-label>
              <mat-select formControlName="EstadoCivil">
                <mat-option [value]="estadoCivil.id" *ngFor="let estadoCivil of lstEstadosCivilModel">
                  {{ estadoCivil.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Ocupación</mat-label>
              <input matInput maxlength="50" formControlName="Ocupacion" />
              <mat-error *ngIf="personaForm.controls['Ocupacion'].hasError('maxlength')">Largo máximo
                excedido</mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Observaciones</mat-label>
              <textarea matInput formControlName="Observacion"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card style="margin-bottom: 20px">
      <mat-card-header>
        <mat-card-title>Contactos</mat-card-title>
        <mat-card-subtitle>Medios de contactos principales</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row">



          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Ubicación</mat-label>
              <input matInput style="text-transform: capitalize" maxlength="50" formControlName="Ubicacion" />
              <mat-error *ngIf="personaForm.controls['Ubicacion'].hasError('maxlength')">Largo máximo
                excedido</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <app-buscar-localidad [localidad]="personaDetailDto.localidad"
              (eventoLocalidad)="seleccionaLocalidad($event)"></app-buscar-localidad>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Código Postal</mat-label>
              <input matInput maxlength="50" formControlName="CodigoPostal" />
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Nacionalidad</mat-label>
              <mat-select formControlName="Pais">
                <mat-option [value]="pais.id" *ngFor="let pais of lstPaises">
                  {{ pais.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <app-button-add [disabled]="!personaForm.valid" (click)="onSubmit()"></app-button-add>
  </form>
</div>
