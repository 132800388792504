<div class="card-container">
  <mat-card>
    <ngx-spinner
      [zIndex]="100"
      name="spCantidadPorEstado"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" var(--primary-color)"
      type="ball-atom"
      [fullScreen]="false"
    ></ngx-spinner>
    <mat-card-header>
      <mat-card-title>Pericias por Estado</mat-card-title>
      <mat-card-subtitle>Cantidad de pericias por estado</mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />
    <mat-card-content>
      <table class="mat-elevation-z8" class="badge-table">
        <tr *ngFor="let item of cantidadPorEstadoDto">
          <td>{{ item.descripcion | titlecase }}</td>
          <td>{{ item.cantidad }}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td>
            <strong>{{ calcularCantidadTotalPericiasPorEstado() }}</strong>
          </td>
        </tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button [routerLink]="['/pericias']">Ver listado</button>
    </mat-card-actions>
  </mat-card>
</div>
