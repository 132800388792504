<div class="position-relative">
  <ngx-spinner
    [zIndex]="100"
    name="spPersona"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  ></ngx-spinner>

  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="primary">close</mat-icon>
  </button>
  <!-- {{data.nombreCompleto}} -->
  <h1 mat-dialog-title>Reactivar <strong></strong></h1>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <p>
      <strong
        >¿Estás seguro de que deseas reactivar el perfil de esta
        Persona?</strong
      >
    </p>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions align="end">
    <button
      *appRole="['Personas.Eliminar']"
      mat-raised-button
      color="primary"
      (click)="reactivar(data)"
    >
      Reactivar
    </button>
    <button mat-button cdkFocusInitial (click)="onNoClick()">Cancelar</button>
  </div>
</div>
