<app-go-back-and-title titulo="Ver Caso"></app-go-back-and-title>

<ngx-spinner [zIndex]="100" name="spView" bdColor="rgba(255,255,255,0.8)" size="default" color=" var(--primary-color)"
  type="ball-atom" [fullScreen]="false">
</ngx-spinner>

<div class="row" *appRole="['Casos.Ver']">
  <div *ngIf="casoDto">
    <mat-tab-group *ngIf="casoDto" animationDuration="0ms">
      <mat-tab label="Caso">
        <div class="container-fluid">
          <br>
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" class="app-button-action"
            matTooltip="Acciones">
            <mat-icon>more_vert</mat-icon> Acciones
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/casos/edit/' + casoDto.id]">
              <mat-icon>edit</mat-icon>Editar
            </button>
          </mat-menu>
          <br><br>
          <div class="row">
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Caso</mat-card-title>
                  <mat-card-subtitle>Información extra el caso principal</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="property-row">
                    <span class="property-name">Caratula</span>
                    <span class="property-value">
                      {{
                      !isFalsy(casoDto.caratula)
                      ? casoDto.caratula
                      : ("" | uppercase)
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Fecha comienzo:</span>
                    <span class="property-value">{{ casoDto.fechaComienzo | date : "short" }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Fecha finalización:</span>
                    <span class="property-value">{{ casoDto.fechaComienzo | date : "short" }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Tipo caso:</span>
                    <span class="property-value">{{
                      casoDto.tipoCaso
                      ? casoDto.tipoCaso.descripcion
                      : ("" | titlecase)
                      }}</span>
                  </div>

                  <div class="property-row">
                    <span class="property-name">Estado caso:</span>
                    <span class="property-value">{{
                      casoDto.estadoCaso
                      ? casoDto.estadoCaso.descripcion
                      : ("" | titlecase)
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Descripción:</span>
                    <span class="property-value">{{
                      casoDto.descripcion
                      ? casoDto.descripcion
                      : ("")
                      }}</span>
                  </div>

                </mat-card-content>
              </mat-card>
            </div>
            <div class="col">
            </div>
          </div>



        </div>
      </mat-tab>
      <mat-tab label="Expedientes">
        <div class="container-fluid">
          <br />
          <div class="row">
            <div class="col">
              <app-list-expediente [idCaso]="casoDto.id"></app-list-expediente>
            </div>
          </div>

        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>



<!--
<mat-grid-list cols="6" rowHeight="4rem">
  <mat-grid-tile [colspan]="6" [rowspan]="9">

  </mat-grid-tile>
  <mat-grid-tile [colspan]="6" [rowspan]="1"></mat-grid-tile>
</mat-grid-list> -->
