import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-status-badge',
  standalone: true,
  imports: [SharedModule],
  template: `<span
  class="property-value status-badge"
  [ngClass]="estado ? 'active' : 'inactive'">
  {{ estado ? "Activo" : "Inactivo" }}
</span>`,
  styleUrls: ['./status-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent {
  @Input() estado!: boolean
}
