<!-- Contenido del Drawer -->
<!-- <app-logo *ngIf="archivo.id != 0" [idArchivo]="archivo.id"></app-logo> -->
<!--jesus sidebar  -->
<!-- Menu items -->
<div *ngIf="!isLoading">
  <!-- <div
    *ngFor="let item of menues; let i = index; trackBy: trackByItems"
    class="menu-item-container"
  >
    <div *ngIf="item.submenues && item.submenues.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="menu" class="menu-button">
        <mat-icon>{{ item.iconName }}</mat-icon>
        {{ item.displayName }}
      </button>

      <mat-menu #menu="matMenu">
        <div *ngFor="let subItem of item.submenues" class="menu-item-container">
          <button
            mat-menu-item
            [routerLink]="'/' + subItem.route"
            class="menu-button"
            (click)="toggleDrawer()"
          >
            <mat-icon>{{ subItem.iconName }}</mat-icon>
            {{ subItem.displayName }}
          </button>
        </div>
      </mat-menu>
    </div>

    <div *ngIf="!item.submenues || item.submenues.length === 0">
      <button
        mat-menu-item
        [routerLink]="'/' + item.route"
        class="menu-button"
        (click)="toggleDrawer()"
        [class.selected]="isSelected(item)"
      >
        <mat-icon>{{ item.iconName }} </mat-icon>
        {{ item.displayName }}
      </button>
    </div>
  </div> -->

  <aside
    id="sidebar-multi-level-sidebar"
    class="top-0 left-0 z-40 w-64 h-screen transition-transform sm:translate-x-0 absolute"
    aria-label="Sidebar"
    style="width: 250px"
  >
    <div
      class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800"
      style="background-color:  var(--primary-color)"
    >
      <ul class="space-y-2 font-medium">
        <li *ngFor="let item of menues; let i = index; trackBy: trackByItems">
          <div *ngIf="item.submenues && item.submenues.length > 0">
            <button
              type="button"
              class="flex items-center w-full p-2 text-base transition duration-75 rounded-lg group dark:text-white dark:hover:bg-gray-700 text-slate-50 submenu-container-nuevo"
              [attr.aria-controls]="item.displayName + i"
              [attr.data-collapse-toggle]="item.displayName + i"
              (click)="toggleCategory(i)"
            >
              <mat-icon>{{ item.iconName }}</mat-icon>
              <span
                class="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                >{{ item.displayName }}</span
              >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <ul
              [id]="item.displayName + i"
              class="hidden py-2 space-y-2"
              [class.hidden]="activeIndex !== i"
            >
              <li
                *ngFor="let subItem of item.submenues"
                class="submenu-container-nuevo text-slate-50 gap-3"
                style="margin-left: 20px"
              >
                <mat-icon>{{ subItem.iconName }}</mat-icon>
                <a (click)="toggleDrawer()" [routerLink]="'/' + subItem.route">
                  {{ subItem.displayName }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</div>
