<mat-card *appRole="['Casos.Listar']">

  <!-- Cabecera -->
  <mat-card-header>
    <app-button-new #tooltip="matTooltip" *appRole="['Casos.Crear']" [routerLink]="['/casos/create']"
      matTooltip="Crear caso"></app-button-new>
  </mat-card-header>

  <!-- Contenido de filtros -->
  <mat-card-content>
    <form [formGroup]="formFilter">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Caratula</mat-label>
            <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Caratula" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <mat-form-field appearance="outline" class="t w-100">
            <ngx-spinner [zIndex]="-1" name="spBusquedaTipoCaso" bdColor="rgba(255,255,255,0.8)" size="small"
              color=" var(--primary-color)" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Tipo Caso </mat-label>
            <mat-select placeholder="Seleccione opcion" formControlName="IdTipoCasoCaso">
              <mat-option [value]="tipoCasos.id" *ngFor="
                  let tipoCasos of tiposCasos;
                  let i = index;
                  trackBy: trackByItems
                ">
                {{ tipoCasos.descripcion }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto">
          <button mat-stroked-button color="primary" (click)="filtrar()">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
          </button>
        </div>
        <div class="col-auto">
          <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
            Filtros Avanzados
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-content>
    <ngx-spinner [zIndex]="100" name="spCaso" bdColor="rgba(255,255,255,0.8)" size="default" color=" var(--primary-color)"
      type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <br>
    <mat-divider></mat-divider>
    <br>
    <!-- Barra de acciones -->
    <div class="row align-items-center">

      <div class="col-auto d-flex align-items-center">
        <app-button-action></app-button-action>
      </div>
      <div class="col-auto d-flex align-items-center">
        <!-- <app-button-export-file *ngIf="data" (click)="exportarTabla()"></app-button-export-file> -->
      </div>
      <!-- Espaciado intermedio -->
      <div class="col"></div>

      <!-- Campo de búsqueda alineado a la derecha -->
      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100 mb-0">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input />
        </mat-form-field>
      </div>
    </div>


    <!-- Tabla De datos -->
    <div>
      <table class="mat-elevation-z8" mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter"
        *ngIf="data" matSort matSortStart="asc">

        <ng-container matColumnDef="select">

          <mat-header-cell mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caratula">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Caratula</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let caso">
            {{ caso.caratula | uppercase }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fechaComienzo">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha
              Comienzo</strong></mat-header-cell>
          <mat-cell mat-cell *matCellDef="let caso">
            {{ caso.fechaComienzo | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fechaFinalizacion">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha Finalización</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let caso">
            {{ caso.fechaFinalizacion | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tipoCaso">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Tipo
              Caso</strong></mat-header-cell>
          <mat-cell mat-cell *matCellDef="let caso">
            {{
            caso.tipoCaso == undefined
            ? ""
            : (caso.tipoCaso.descripcion | uppercase)
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="estadoCaso">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado Caso</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let caso">
            {{
            caso.estadoCaso == undefined
            ? ""
            : (caso.estadoCaso.descripcion | uppercase)
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activo">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let caso">
            <app-status-badge [estado]="caso.activo"></app-status-badge>
          </mat-cell>
        </ng-container>

        <!-- Accion Column -->
        <ng-container matColumnDef="accion">
          <mat-header-cell mat-header-cell *matHeaderCellDef>
            <strong>Acción</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let audiencia" class="action-link">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

              <button mat-menu-item [routerLink]="['/casos/', audiencia.id]">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button>
              <button mat-menu-item [routerLink]="['/casos/edit/', audiencia.id]">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
              <button mat-menu-item (click)="openDialog('Eliminar', audiencia)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
      <app-sin-datos-para-mostrar [dataSource]="dataSource"></app-sin-datos-para-mostrar>
      <mat-paginator showFirstLastButtons class="paginator" *ngIf="data" [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
      </mat-paginator>
    </div>

  </mat-card-content>
</mat-card>
