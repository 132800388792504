import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContactoDto } from '@models/contacto/contactoDto.model';

@Component({
  selector: 'app-card-view-contactos-simple',
  templateUrl: './card-view-contactos-simple.component.html',
  styleUrls: ['./card-view-contactos-simple.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardViewContactosSimpleComponent {
  @Input() contactos: ContactoDto[] = [];


}
