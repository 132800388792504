<h1 mat-dialog-title>Acción realizada :: <strong>Modificar</strong></h1>
<br />
<mat-dialog-content>
  <ngx-spinner
    [zIndex]="100"
    name="spUpdate"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-8">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Titulo</mat-label>
          <input
            matInput
            type="text"
            maxlength="100"
            formControlName="Titulo"
          />
          <mat-error *ngIf="form.controls['Titulo'].hasError('required')"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
          <mat-error *ngIf="form.controls['Titulo'].hasError('maxlength')"
            >Supero el largo maximo.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Estado</mat-label>
          <mat-select
            placeholder="Seleccione opcion"
            formControlName="EstadoTarea"
          >
            <mat-option
              [value]="estado.id"
              *ngFor="
                let estado of lstEstados;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ estado.descripcion }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="example-full-width col-lg-12">
        <mat-label>Descripcion</mat-label>
        <textarea
          matInput
          #message
          name="Descripcion"
          formControlName="Descripcion"
        ></textarea>
        <mat-error *ngIf="form.controls['Descripcion'].hasError('required')"
          >Este campo es <strong>requerido</strong>.</mat-error
        >
        <mat-hint align="start">Amplie información</mat-hint>
        <mat-hint align="end">{{ message.value.length }} / 2000</mat-hint>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Nivel Avance</mat-label>
          <mat-select
            placeholder="Seleccione opcion"
            formControlName="NivelAvance"
          >
            <mat-option
              [value]="nivel.id"
              *ngFor="
                let nivel of lstNiveles;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ nivel.descripcion }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Prioridad</mat-label>
          <mat-select
            placeholder="Seleccione opcion"
            formControlName="Prioridad"
          >
            <mat-option
              [value]="prioridad.id"
              *ngFor="
                let prioridad of lstPrioridades;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ prioridad.descripcion }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Fecha vencimiento</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="MM/DD/YYYY"
            formControlName="FechaVencimiento"
          />
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    name="Agregar"
    [disabled]="!form.valid"
    (click)="onSubmit()"
  >
  <span>Agregar</span>
  </button>
</mat-dialog-actions>
