<div class="position-relative">
  <ngx-spinner
    name="spModificarCasoUso"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline">
          <input
            placeholder="Descripción"
            [disabled]="true"
            type="text"
            formControlName="Descripcion"
            matInput
          />
          <mat-error *ngIf="f.Descripcion.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline">
          <input
            placeholder="Icono"
            type="text"
            [disabled]="true"
            matTooltip="Use nombre de font-awesome"
            formControlName="Icono"
            matInput
          />
          <mat-error *ngIf="f.Icono.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline">
          <input
            formControlName="Path"
            [disabled]="true"
            placeholder="Path"
            type="text"
            matInput
          />
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline" class="example-full-width">
          <ngx-spinner
            [zIndex]="100"
            name="spBusquedaMenu"
            bdColor="rgba(255,255,255,0.8)"
            size="small"
            color=" var(--primary-color)"
            type="line-scale"
            [fullScreen]="false"
          ></ngx-spinner>
          <mat-label>Menu</mat-label>
          <mat-select
            placeholder="Seleccione opcion"
            [disabled]="true"
            formControlName="Menu"
          >
            <mat-option
              [value]="menu.id"
              *ngFor="let menu of menus; let i = index; trackBy: trackByItems"
            >
              {{ menu.descripcion }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="f.Menu.errors?.required"
            >Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
          <span>Cancelar</span>
        </button>
      </div>
    </div>
  </form>
</div>
