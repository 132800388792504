<h1 mat-dialog-title>Nueva Persona</h1>
<mat-dialog-content *appRole="['Personas.Crear']">
  <mat-card-content>
    <form [formGroup]="personaForm">
      <ngx-spinner
        [zIndex]="100"
        name="spPersona"
        bdColor="rgba(255,255,255,0.8)"
        size="default"
        color=" var(--primary-color)"
        type="ball-atom"
        [fullScreen]="false"
      >
      </ngx-spinner>
      <div class="row">
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nombre</mat-label>
            <input
              matInput
              type="text"
              maxlength="50"
              style="text-transform: capitalize"
              formControlName="Nombre"
            />
            <mat-error
              *ngIf="personaForm.controls['Nombre'].hasError('required')"
              >Este campo es <strong>requerido</strong>.</mat-error
            >
            <mat-error
              *ngIf="personaForm.controls['Nombre'].hasError('maxlength')"
              >Supero el largo maximo.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Apellido</mat-label>
            <input
              matInput
              style="text-transform: capitalize"
              maxlength="50"
              type="text"
              formControlName="Apellido"
            />
            <mat-error
              *ngIf="personaForm.controls['Apellido'].hasError('maxlength')"
              >Supero el largo maximo.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Correo</mat-label>
            <input matInput type="email" formControlName="Correo" />
            <mat-error
              *ngIf="personaForm.controls['Correo'].hasError('required')"
              >Este campo es <strong>requerido</strong>.</mat-error
            >
            <mat-error *ngIf="personaForm.controls['Correo'].hasError('email')"
              >Formato invalido.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telefono Personal</mat-label>
            <!-- <span matPrefix>+54 &nbsp;</span> -->
            <input
              matInput
              maxlength="20"
              placeholder="Sin 0 y sin 15"
              type="number"
              formControlName="TelefonoPrincipal"
            />

            <mat-error
              *ngIf="
                personaForm.controls['TelefonoPrincipal'].hasError('required')
              "
              >Este campo es <strong>requerido</strong>.</mat-error
            >
            <mat-error
              *ngIf="
                personaForm.controls['TelefonoPrincipal'].hasError('maxlength')
              "
              >Supero el largo maximo.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    name="Agregar"
    [disabled]="!personaForm.valid"
    *appRole="['Personas.Crear']"
    (click)="onSubmit()"
  >
  <span>Agregar</span>
  </button>
</mat-dialog-actions>
