<h1 mat-dialog-title>
  Acción realizada :: <strong>{{ local_data.action }}</strong>
</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <ngx-spinner
    [zIndex]="100"
    name="spLoadGeneric"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div
        class="col"
        *ngIf="local_data.action != 'Eliminar'; else elseTemplate"
      >
        <mat-form-field
          appearance="outline"
          class="example-full-width col-lg-12"
        >
          <mat-label>Descripción</mat-label>
          <input
            matInput
            #message
            name="Descripcion"
            formControlName="Descripcion"
            [disabled]="local_data.action == 'Ver'"
          />
          <mat-error *ngIf="form.controls['Descripcion'].hasError('maxlength')"
            >Supero el largo maximo.</mat-error
          >
          <mat-error *ngIf="form.controls['Descripcion'].hasError('required')"
            >Supero el largo maximo.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </form>
  <ng-template #elseTemplate>
    ¿Esta seguro que desea eliminar el registro
    <b>{{ local_data.descripcion }}</b
    >? </ng-template
  ><br />
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    *ngIf="local_data.action != 'Ver'"
    color="primary"
    (click)="doAction()"
    [disabled]="!form.valid"
  >
    {{ local_data.action }}
  </button>
</div>
