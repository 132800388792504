<div *appRole="['Audiencias.Crear']">
  <button
    mat-raised-button
    color="primary"
    [routerLink]="['/audiencias/create']"
    #tooltip="matTooltip"
    matTooltip="Crear audiencia"
  >
    <mat-icon>add</mat-icon>
    <span>Nuevo</span>
  </button>
  <br />
  <br />
</div>

<mat-card *appRole="['Audiencias.Listar']">
  <mat-card-header *ngIf="idExpediente == undefined">
    <div class="row">
      <div class="col">
        <button mat-stroked-button color="primary" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>
      </div>
    </div>
    <br />
    <br />
  </mat-card-header>

  <mat-card-content>
    <ngx-spinner
      [zIndex]="100"
      name="spAudiencia"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" var(--primary-color)"
      type="ball-atom"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div class="row align-items-center">
      <!-- <div class="col">
        <button mat-raised-button [matMenuTriggerFor]="menu" color="primary"  *ngIf="data">Acciones
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
        </mat-menu>
      </div> -->
      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </div>
    </div>

    <table
      class="mat-elevation-z8"
      mat-table
      [dataSource]="dataSource"
      *ngIf="data"
      matSort
      matSortStart="asc"
    >
      <ng-container matColumnDef="expediente">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Caratula</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let audiencia">
          {{ audiencia.expediente.caratula | uppercase }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lugar">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Lugar</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let audiencia">
          {{ audiencia.lugar | uppercase }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sala">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Sala</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let audiencia">
          {{ audiencia.sala | uppercase }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header
          ><strong>Fecha</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let audiencia">
          {{ audiencia.fecha | date : "short" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tipoAudiencia">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header
          ><strong>Tipo Audiencia</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let audiencia">
          {{
            audiencia.tipoAudiencia == undefined
              ? ""
              : (audiencia.tipoAudiencia.descripcion | uppercase)
          }}
        </mat-cell>
      </ng-container>

      <!-- Accion Column -->
      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Acción</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let audiencia" class="action-link">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              [routerLink]="['/audiencias/', audiencia.id]"
              *appRole="['Audiencias.Ver']"
            >
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button>
            <button
              mat-menu-item
              [routerLink]="['/audiencias/edit/', audiencia.id]"
              *appRole="['Audiencias.Modificar']"
            >
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button
              mat-menu-item
              (click)="openDialog('Eliminar', audiencia)"
              *appRole="['Audiencias.Eliminar']"
            >
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        matRipple
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </table>
    <div class="no-data-table">
      <span class="with-icon" *ngIf="dataSource.data.length == 0"
        ><mat-icon>search_off</mat-icon>No se encontraron datos para
        mostrar.</span
      >
    </div>
    <mat-paginator
      showFirstLastButtons
      class="paginator"
      *ngIf="data"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
