<div class="position-relative">
  <ngx-spinner
    name="spCrearPermiso"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline">
          <input
            placeholder="Descripción"
            type="text"
            formControlName="Descripcion"
            matInput
          />
          <mat-error *ngIf="f.Descripcion.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline">
          <input
            placeholder="Token"
            type="text"
            formControlName="Token"
            matInput
          />
          <mat-error *ngIf="f.Token.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline" class="example-full-width">
          <ngx-spinner
            [zIndex]="-1"
            name="spBusquedaRoles"
            bdColor="rgba(255,255,255,0.8)"
            size="small"
            color=" var(--primary-color)"
            type="line-scale"
            [fullScreen]="false"
          ></ngx-spinner>
          <mat-label>Rol</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="Rol">
            <mat-option
              [value]="rol.id"
              *ngFor="let rol of roles; let i = index; trackBy: trackByItems"
            >
              {{ rol.descripcion }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="f.Rol.errors?.required"
            >Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline" class="example-full-width">
          <ngx-spinner
            [zIndex]="-1"
            name="spBusquedaCasosUso"
            bdColor="rgba(255,255,255,0.8)"
            size="small"
            color=" var(--primary-color)"
            type="line-scale"
            [fullScreen]="false"
          ></ngx-spinner>
          <mat-label>Caso Uso</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="CasoUso">
            <mat-option
              [value]="casoUso.id"
              *ngFor="
                let casoUso of casosUso;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ casoUso.descripcion }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="f.CasoUso.errors?.required"
            >Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <button
          mat-raised-button
          color="primary"
          *appRole="['Permisos.Crear']"
          (click)="doAction()"
          [disabled]="!form.valid"
        >
        <span>Agregar</span>
        </button>
      </div>
    </div>
  </form>
</div>
