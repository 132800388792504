<app-go-back-and-title titulo="Ver Expediente"></app-go-back-and-title>
<ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default" color=" var(--primary-color)"
  type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div class="row" *appRole="['Expedientes.Ver']">
  <div *ngIf="expedienteDto">
    <mat-tab-group>

      <mat-tab label="Expediente">
        <div class="container-fluid">
          <br>
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" class="app-button-action"
            matTooltip="Acciones">
            <mat-icon>more_vert</mat-icon> Acciones
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="generarCedulaNotificacion(expedienteDto)">
              Generar cedula notificación
            </button>
            <button mat-menu-item [routerLink]="['/expedientes/edit/' + expedienteDto.id]">
              <mat-icon>edit</mat-icon>Editar
            </button>
            <button mat-menu-item (click)="openDialogDelete(expedienteDto)">
             <mat-icon>delete</mat-icon> Eliminar
            </button>
          </mat-menu>
          <br><br>
          <div class="row">
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Caratula del expediente</mat-card-title>
                  <mat-card-subtitle>Detalle de la caratula del expediente</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="property-row">

                    <span class="property-name">Cuij:</span>
                    <span class="property-value">{{ expedienteDto.cuij }}
                    </span>
                    <!-- <button class="property-value" (click)="copiarAlPortapapeles(expedienteDto.cuij)"
                      matTooltip="Copiar al portapapeles" aria-label="Copiar cuij al portapapeles">
                      Copiar
                    </button> -->
                  </div>
                  <div class="property-row">
                    <span class="property-name">Demanda:</span>
                    <span class="property-value">{{ expedienteDto.demanda | uppercase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Demandado:</span>
                    <span class="property-value">{{ expedienteDto.demandado | uppercase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Juzgado:</span>
                    <span class="property-value">{{
                      expedienteDto.organismo == undefined
                      ? ""
                      : (expedienteDto.organismo.nombre | titlecase)
                      }}</span>
                    <!-- <button class="icon-display" mat-icon-button (click)="
                      copiarAlPortapapeles(expedienteDto.organismo.nombre)
                    " matTooltip="Copiar al portapapeles" aria-label="Copiar organismo al portapapeles">
                    <mat-icon>content_copy</mat-icon>
                  </button> -->
                  </div>
                  <div class="property-row">
                    <span class="property-name">Causa:</span>
                    <span class="property-value">{{ expedienteDto.causa | uppercase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Numero:</span>
                    <span class="property-value">{{ expedienteDto.numero | uppercase }}</span>
                  </div>

                  <div class="property-row">
                    <span class="property-name">Sufijo:</span>
                    <span class="property-value">{{ expedienteDto.sufijo | uppercase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Bis:</span>
                    <span class="property-value">{{ expedienteDto.bis | uppercase }}</span>
                  </div>


                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Estados del expediente</mat-card-title>
                  <mat-card-subtitle>Información extra del expediente</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="property-row">
                    <span class="property-name">Ubicación actual:</span>
                    <span class="property-value">{{ expedienteDto.ubicacion | titlecase }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Clave:</span>
                    <span class="property-value">{{ expedienteDto.claveSisfeExpediente }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Fecha ingreso MEU:</span>
                    <span class="property-value">{{
                      expedienteDto.fechaIngresoMEU | date : "short"
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Monto demanda:</span>
                    <span class="property-value">{{ expedienteDto.montoDemanda | currency }}</span>
                  </div>

                  <div class="property-row">
                    <span class="property-name">Tipo expediente:</span>
                    <span class="property-value">{{
                      expedienteDto.tipoExpediente == undefined
                      ? ""
                      : (expedienteDto.tipoExpediente.descripcion | uppercase)
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Estado expediente::</span>
                    <span class="property-value">{{
                      expedienteDto.estadoExpediente == undefined
                      ? ""
                      : (expedienteDto.estadoExpediente.descripcion | uppercase)
                      }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Estado expediente perito::</span>
                    <span class="property-value">{{
                      expedienteDto.estadoExpedientePerito == undefined
                      ? ""
                      : (expedienteDto.estadoExpedientePerito.descripcion
                      | uppercase)
                      }}</span>
                  </div>

                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Descripción general del expediente</mat-card-title>
                  <mat-card-subtitle>Pequeña descripción que detalle el expediente</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <span><strong>Descripción:</strong></span>
                  <span>{{ expedienteDto.descripcion }}</span>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-6">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Auditoria Expediente</mat-card-title>
                  <mat-card-subtitle>Trazabilidad y historial de cambios</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>

                  <div class="property-row">
                    <span class="property-name">Fecha Alta:</span>
                    <span class="property-value">{{ expedienteDto.fechaAlta | date : "short" }}</span>
                  </div>
                  <div class="property-row">
                    <span class="property-name">Ultima modificación:</span>
                    <span class="property-value">{{
                      expedienteDto.fechaModificacion | date : "short"
                      }}</span>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button color="accent">Historial de Cambios</button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <br>

      </mat-tab>
      <mat-tab label="Partes">
        <div class="container-fluid">
          <br>
          <app-form-expediente-partes></app-form-expediente-partes>
        </div>
      </mat-tab>
      <mat-tab label="Notas">
        <div class="container-fluid">
          <br>
          <app-list-expediente-nota [idExpediente]="expedienteDto.id"
            *appRole="['ExpedientesNotas.Listar']"></app-list-expediente-nota>
        </div>
      </mat-tab>
      <mat-tab label="Tareas">
        <div class="container-fluid">
          <br>
          <app-list-tarea [idExpediente]="expedienteDto.id" *appRole="['Tareas.Listar']"></app-list-tarea>
        </div>
      </mat-tab>
      <mat-tab label="Pericias" *appRole="['Pericias.Listar']">
        <div class="container-fluid">
          <br>
          <app-button-new *appRole="['Pericias.Crear']" (click)="onNuevaPericia()" matTooltip="Crear pericia" ></app-button-new>
        </div>
        <mat-card>
          <div *appRole="['Pericias.Listar']">
            <mat-card-content *ngIf="dataSourcePericias.data.length >= 1">
              <ngx-spinner [zIndex]="100" name="spPericias" bdColor="rgba(255,255,255,0.8)" size="default"
                color=" var(--primary-color)" type="ball-atom" [fullScreen]="false"></ngx-spinner>
              <table class="mat-elevation-z8" mat-table [dataSource]="dataSourcePericias">
                <ng-container matColumnDef="lugarPericia">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Lugar</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.lugar }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="fechaPropuesta">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Fecha Propuesta</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fechaPropuesta | date : "short" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="fechaRealiza">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Fecha Realiza</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fechaRealizada | date : "short" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="tipoPericia">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Tipo Pericia</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                    element.tipoPericia
                    ? element.tipoPericia.descripcion
                    : ("" | titlecase)
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="estadoPericia">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Estado</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                    element.estadoPericia
                    ? element.estadoPericia.descripcion
                    : ("" | titlecase)
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="accion" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Acción</strong>
                  </th>
                  <td mat-cell *matCellDef="let pericia" class="action-link">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item [routerLink]="['/pericias/', pericia.id]" *appRole="['Pericias.Ver']">
                        <mat-icon>visibility</mat-icon>
                        <span>Ver</span>
                      </button>
                      <button mat-menu-item [routerLink]="['/pericias/edit/', pericia.id]"
                        *appRole="['Pericias.Modificar']">
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>
                      <button mat-menu-item (click)="openDialogPericia('Eliminar', pericia)"
                        *appRole="['Pericias.Eliminar']">
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsPericias"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPericias"></tr>
              </table>
            </mat-card-content>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Audiencias" *appRole="['Audiencias.Listar']">
        <div class="container-fluid">
          <br>
          <app-button-new *appRole="['Audiencias.Crear']" (click)="onNuevaAudiencia()" matTooltip="Crear audiencia" ></app-button-new>
          <mat-card>
            <div *appRole="['Audiencias.Listar']">
              <mat-card-content *ngIf="dataSourceAudiencia.data.length >= 1">
                <ngx-spinner [zIndex]="100" name="spAudiencias" bdColor="rgba(255,255,255,0.8)" size="default"
                  color=" var(--primary-color)" type="ball-atom" [fullScreen]="false"></ngx-spinner>
                <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceAudiencia">
                  <ng-container matColumnDef="lugar">
                    <th mat-header-cell *matHeaderCellDef>
                      <strong>Lugar</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.lugar }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sala">
                    <th mat-header-cell *matHeaderCellDef>
                      <strong>Sala</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.sala }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef>
                      <strong>Fecha</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.fecha | date : "short" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tipoAudiencia">
                    <th mat-header-cell *matHeaderCellDef>
                      <strong>Tipo Audiencia</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{
                      element.tipoAudiencia == undefined
                      ? ""
                      : element.tipoAudiencia.descripcion
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accion" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef>
                      <strong>Acción</strong>
                    </th>
                    <td mat-cell *matCellDef="let pericia">
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="['/audiencias/', pericia.id]" *appRole="['Audiencias.Ver']">
                          <mat-icon>visibility</mat-icon>
                          <span>Ver</span>
                        </button>
                        <button mat-menu-item [routerLink]="['/audiencias/edit/', pericia.id]"
                          *appRole="['Audiencias.Modificar']">
                          <mat-icon>edit</mat-icon>
                          <span>Editar</span>
                        </button>
                        <button mat-menu-item (click)="openDialogAudiencia('Eliminar', pericia)"
                          *appRole="['Audiencias.Eliminar']">
                          <mat-icon>delete</mat-icon>
                          <span>Eliminar</span>
                        </button>
                      </mat-menu>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsAudiencias"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsAudiencias"></tr>
                </table>
              </mat-card-content>
            </div>
          </mat-card>
        </div>

      </mat-tab>
      <mat-tab label="Movimientos" *appRole="['Movimientos.Listar']">
        <div class="container-fluid">
          <br>
          <app-list-movimiento [idExpediente]="expedienteDto.id"
              *appRole="['Movimientos.Listar']"></app-list-movimiento>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
