<mat-card class="contact-card">
  <ngx-spinner [zIndex]="100" name="spContactos" bdColor="rgba(255,255,255,0.8)" size="default" color="var(--primary-color)"
  type="ball-atom" [fullScreen]="false">
</ngx-spinner>
  <mat-card-header>
    <mat-card-title>Lista de Contactos</mat-card-title>
    <mat-card-subtitle>Información general sobre los contactos</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div *ngFor="let contacto of contactos" class="contact-item">
      <div class="summary-row">
        <span class="contact-type">{{ contacto.tipoContacto.descripcion }}</span>
        <span class="contact-value">{{ contacto.valorContacto }}</span>
        <span class="contact-preferred">
          Preferido: <strong>{{ contacto.preferido ? 'Sí' : 'No' }}</strong>
        </span>
        <button mat-icon-button (click)="contacto.expanded = !contacto.expanded">
          <mat-icon>{{ contacto.expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
      </div>
      <div *ngIf="contacto.expanded" class="detail-row">
        <div class="property-row">
          <span class="property-name">Empresa Asociada:</span>
          <span class="property-value"> {{ contacto.empresaAsociada }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Fecha Último Contacto:</span>
          <span class="property-value">{{ contacto.fechaUltimoContacto | date }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Fuente Contacto:</span>
          <span class="property-value">{{ contacto.fuenteContacto | date }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Tipo Relación:</span>
          <span class="property-value">{{ contacto.tipoRelacion }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Tipo Relación:</span>
          <span class="property-value">{{ contacto.tipoRelacion }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Categorías/Etiquetas:</span>
          <span class="property-value">{{ contacto.categoriasEtiquetas }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Historial de Interacciones:</span>
          <span class="property-value"> {{ contacto.historialInteracciones }}</span>
        </div>
        <div class="property-row">
          <span class="property-name">Preferencias Contacto:</span>
          <span class="property-value"> {{ contacto.preferenciasContacto }}</span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
