<div class="position-relative">
  <ngx-spinner
    name="spModificarRol"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" var(--primary-color)"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline">
          <input
            placeholder="Descripción"
            type="text"
            formControlName="Descripcion"
            matInput
          />
          <mat-error *ngIf="f.Descripcion.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <button
          mat-raised-button
          color="primary"
          *appRole="['Roles.Modificar']"
          (click)="doAction()"
          [disabled]="!form.valid"
        >
        <span>Agregar</span>
        </button>
      </div>
    </div>
  </form>
</div>
